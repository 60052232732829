import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  public totalRequests = 0;

  constructor(private cmService: CommonService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): import('rxjs').Observable<HttpEvent<any>> {
    this.totalRequests++;
    if (this.totalRequests === 1) {
      this.cmService.isLoading = true;
    } else {
      this.cmService.isLoading = false;
    }
    return <any>next.handle(req).pipe(
      catchError((err) => {
        // this.handleAuthError(err);
        throw err;
      }),

      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.cmService.isLoading = false;
        } else {
          this.cmService.isLoading = true;
        }
      })
    );
  }

  // private handleAuthError(err: any) {
  //     if (err.status === 401) {
  //         this.authService.logout();
  //     }
  // }
}
