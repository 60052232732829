@if (dataSet && dataSet.show && !dataSet.hide) {

  @if (dataSet && dataSet.isLabel) {

    <ng-container *ngTemplateOutlet="menuLabel"></ng-container>

}

  @if (dataSet && !dataSet.isLabel) {

    <ng-container *ngTemplateOutlet="menuLink"></ng-container>

}

} @else {

  <li [tabindex]="0">
    <ng-content></ng-content>
  </li>

}

<!--  Template for custom content passed in menu  -->


<!--  Template for menu label  -->
<ng-template #menuLabel>
  <li
    class="menu-item-label"
    [aria-label]="dataSet.name"
    [attr.aria-describedby]="dataSet.name"
    #menuLink
  >
    {{ dataSet.name }}
  </li>
</ng-template>

<!--  Template for menu link  -->
<ng-template #menuLink>
  <li
    class="menu-item-container"
    [tabindex]="dataSet.path ? 0 : -1"
    [attr.aria-describedby]="dataSet.name"
    [attr.aria-disabled]="dataSet.disabled ? 'true' : 'false'"
    [attr.data-transaction-name]="'menu-item-'+(dataSet.path || 'unknown')"
    [ngClass]="{ disabled: dataSet.disabled }"
    [routerLink]="[dataSet.path]"
    routerLinkActive="active"
    #menuLinkContent
    [class.active]="menuLinkContent.setAttribute('expand', true)"
  >
    <span class="menu-wrapper">
      @if (dataSet.icon) {

        <span class="menu-icon-wrapper">
          <img
            src="assets/images/menu/{{ dataSet.icon }}.svg"
            alt="dataset-icon"
            class="menu-icon"
          />
        </span>

}
      <span class="menu-label">{{ dataSet.name }}</span>
    </span>
  </li>
</ng-template>
