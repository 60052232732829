import {
  EAchPaymentType,
  EOfferPricingType,
  EPaymentMethodType,
} from '@parameters/offer-pricing/offer-pricing.parameter';

export enum EAddPricingFormKeys {
  visualizeBrand = 'preferredBrandingDomainId',
  agentId = 'relationshipId',
  tagLine = 'tagLine',
  paymentMethodType = 'paymentMethodType',
  pricingType = 'pricingType',
  achCoreFees = 'achCoreFees',
  flatRateCoreFees = 'flatRateCoreFees',
  interchangePlusCoreFees = 'interchangePlusCoreFees',
  cashDiscountCoreFees = 'cashDiscountCoreFees',
  tieredCoreFees = 'tieredCoreFees',
}

export enum EFlatRateFormKeys {
  flatRateSwipedPercentage = 'flatRateSwipedPercentage',
  flatRateSwipedPerItem = 'flatRateSwipedPerItem',
  flatRateKeyedInPercentage = 'flatRateKeyedInPercentage',
  flatRateKeyedInPerItem = 'flatRateKeyedInPerItem',
}

export enum EAchCoreFormKeys {
  achPricingType = 'achPricingType',
  debits = 'debits',
  credits = 'credits',
}

export enum EDebitAchCoreFormKeys {
  achDebitDiscountFee = 'achDebitDiscountFee',
  achDebitPerItemFee = 'achDebitPerItemFee',
  achDebitMaxFeePerTransaction = 'achDebitMaxFeePerTransaction',
  achDebitRefundFee = 'achDebitRefundFee',
}

export enum ECreditAchCoreFormKeys {
  achCreditDiscountFee = 'achCreditDiscountFee',
  achCreditPerItemFee = 'achCreditPerItemFee',
  achCreditMaxFeePerTransaction = 'achCreditMaxFeePerTransaction',
}

export enum ECashDiscountFormKeys {
  programRecurringFee = 'programRecurringFee',
  cashDiscountProgramRatePercentage = 'cashDiscountProgramRatePercentage',
}

export enum EInterchangePlusFormKeys {
  intPlusDiscountRatePercentage = 'intPlusDiscountRatePercentage',
  intPlusAuthFee = 'intPlusAuthFee',
  intPlusAmexDiscountRatePercentage = 'intPlusAmexDiscountRatePercentage',
  intPlusAmexAuthFee = 'intPlusAmexAuthFee',
  intPlusPerItem = 'intPlusPerItem',
  highlightPerItemFees = 'highlightPerItemFees',
  intPlusPinDebitAuthFee = 'intPlusPinDebitAuthFee',
  intPlusEbtAuthFee = 'intPlusEbtAuthFee',
}

export enum ETieredFormKeys {
  tieredType = 'tieredType',
  tieredDiscountRatePercentage = 'tieredDiscountRatePercentage',
  tieredMidQualRatePercentage = 'tieredMidQualRatePercentage',
  tieredNonQualRatePercentage = 'tieredNonQualRatePercentage',
  tieredAmexDiscountRatePercentage = 'tieredAmexDiscountRatePercentage',
  tieredSignatureDebitPercentage = 'tieredSignatureDebitPercentage',
  tieredDiscountRatePerItem = 'tieredDiscountRatePerItem',
  tieredMidQualRatePerItem = 'tieredMidQualRatePerItem',
  tieredNonQualRatePerItem = 'tieredNonQualRatePerItem',
  tieredAmexDiscountRatePerItem = 'tieredAmexDiscountRatePerItem',
  tieredSignatureDebitPerItem = 'tieredSignatureDebitPerItem',
  highlightAuthFees = 'highlightAuthFees',
  tieredAuthFee = 'tieredAuthFee',
  tieredAmexAuthFee = 'tieredAmexAuthFee',
  tieredPinDebitAuthFee = 'tieredPinDebitAuthFee',
  tieredEbtAuthFee = 'tieredEbtAuthFee',
}

export enum ETieredOfferType {
  oneTier = 'one_tier',
  twoTier = 'two_tier',
  threeTier = 'three_tier',
}

export const CTieredOfferTypeLabel = {
  [ETieredOfferType.oneTier]: 'Single Tier',
  [ETieredOfferType.twoTier]: 'Two Tier',
  [ETieredOfferType.threeTier]: 'Three Tier',
};

export const CTieredOfferTypeList = [
  {
    value: ETieredOfferType.oneTier,
    key: CTieredOfferTypeLabel[ETieredOfferType.oneTier],
  },
  {
    value: ETieredOfferType.twoTier,
    key: CTieredOfferTypeLabel[ETieredOfferType.twoTier],
  },
  {
    value: ETieredOfferType.threeTier,
    key: CTieredOfferTypeLabel[ETieredOfferType.threeTier],
  },
];

export const CAddPricingForm = {
  [EAddPricingFormKeys.visualizeBrand]: '',
  [EAddPricingFormKeys.agentId]: '',
  [EAddPricingFormKeys.tagLine]: '',
  [EAddPricingFormKeys.paymentMethodType]: EPaymentMethodType.CARD_ONLY,
  [EAddPricingFormKeys.pricingType]: EOfferPricingType.FLAT_RATE,
};

export const CFlatRateForm = {
  [EFlatRateFormKeys.flatRateSwipedPercentage]: null,
  [EFlatRateFormKeys.flatRateSwipedPerItem]: null,
  [EFlatRateFormKeys.flatRateKeyedInPercentage]: null,
  [EFlatRateFormKeys.flatRateKeyedInPerItem]: null,
};

export const CDebitAchCoreFeesForm = {
  [EDebitAchCoreFormKeys.achDebitDiscountFee]: null,
  [EDebitAchCoreFormKeys.achDebitPerItemFee]: null,
  [EDebitAchCoreFormKeys.achDebitMaxFeePerTransaction]: null,
  [EDebitAchCoreFormKeys.achDebitRefundFee]: null,
};

export const CCreditAchCoreFeesForm = {
  [ECreditAchCoreFormKeys.achCreditDiscountFee]: null,
  [ECreditAchCoreFormKeys.achCreditPerItemFee]: null,
  [ECreditAchCoreFormKeys.achCreditMaxFeePerTransaction]: null,
};

export const CAchCoreFeesForm = {
  [EAchCoreFormKeys.achPricingType]: EAchPaymentType.DEBITS_ONLY,
  [EAchCoreFormKeys.debits]: CDebitAchCoreFeesForm,
  [EAchCoreFormKeys.credits]: CCreditAchCoreFeesForm,
};

export const CCashDiscountForm = {
  [ECashDiscountFormKeys.programRecurringFee]: null,
  [ECashDiscountFormKeys.cashDiscountProgramRatePercentage]: null,
};

export const CInterchangePlusForm = {
  [EInterchangePlusFormKeys.intPlusDiscountRatePercentage]: null,
  [EInterchangePlusFormKeys.intPlusAuthFee]: null,
  [EInterchangePlusFormKeys.intPlusAmexDiscountRatePercentage]: null,
  [EInterchangePlusFormKeys.intPlusAmexAuthFee]: null,
  [EInterchangePlusFormKeys.intPlusPerItem]: null,
  [EInterchangePlusFormKeys.highlightPerItemFees]: false,
  [EInterchangePlusFormKeys.intPlusPinDebitAuthFee]: null,
  [EInterchangePlusFormKeys.intPlusEbtAuthFee]: null,
};

export const CTieredForm = {
  [ETieredFormKeys.tieredType]: ETieredOfferType.oneTier,
  [ETieredFormKeys.tieredDiscountRatePercentage]: null,
  [ETieredFormKeys.tieredMidQualRatePercentage]: null,
  [ETieredFormKeys.tieredNonQualRatePercentage]: null,
  [ETieredFormKeys.tieredAmexDiscountRatePercentage]: null,
  [ETieredFormKeys.tieredSignatureDebitPercentage]: null,
  [ETieredFormKeys.tieredDiscountRatePerItem]: null,
  [ETieredFormKeys.tieredMidQualRatePerItem]: null,
  [ETieredFormKeys.tieredNonQualRatePerItem]: null,
  [ETieredFormKeys.tieredAmexDiscountRatePerItem]: null,
  [ETieredFormKeys.tieredSignatureDebitPerItem]: null,
  [ETieredFormKeys.highlightAuthFees]: false,
  [ETieredFormKeys.tieredAuthFee]: null,
  [ETieredFormKeys.tieredAmexAuthFee]: null,
  [ETieredFormKeys.tieredPinDebitAuthFee]: null,
  [ETieredFormKeys.tieredEbtAuthFee]: null,
};
