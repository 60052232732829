@if (isContentLoaded) {

  <div [class.details--page--loading]="isContentLoaded"></div>

}

@if (validateSidebar) {

  <!-- NAVBAR -->
  <ng-container *ngTemplateOutlet="navbar"></ng-container>
  <!--/ NAVBAR -->

  <!-- MENU -->
  <ng-container *ngTemplateOutlet="menu"></ng-container>
  <!--/ MENU -->

  <!-- APP-CONTENT -->
  <div class="app-content content">
    <div class="content-overlay"></div>
    @if (!coreConfig.layout.navbar.hidden) {
<div
      class="header-navbar-shadow"

    ></div>
}
    <!-- CONTENT -->
    <content></content>
    <!--/ CONTENT -->
  </div>
  <!--/ APP-CONTENT -->

  <!-- PARTIALS ---------------------------------------------------------------------------------------- -->

  <!-- NAVBAR -->
  <ng-template #navbar>
    @if (!coreConfig.layout.navbar.hidden) {
<app-navbar

      class="header-navbar navbar align-items-center navbar-shadow navbar-light floating-nav container-fluid"
      [class.margin-0]="validateSidebar && !hasDashboardRoute"
    >
    </app-navbar>
}
  </ng-template>
  <!--/ NAVBAR -->

  <!-- MENU -->
  <ng-template #menu>
    @if (!coreConfig.layout.menu.hidden && hasDashboardRoute) {
<core-sidebar
      name="menu"
      [collapsed]="false"

      class="main-menu menu-fixed menu-accordio menu-shadow"
      [ngClass]="['menu-light', 'expanded']"
    >
    </core-sidebar>
}
  </ng-template>
  <!--/ MENU -->

}

@if (
    !validateSidebar && sidebarData && sidebarData.length && !isContentLoaded
  ) {

  <app-sidebar-container>
    <app-sidenav
      #sidenav
      [opened]="sidebarOpened"
      [mode]="sidebarMode"
      (toggleSidenav)="handleSidenav($event)"
      class="sidenav-container"
    >
      @if (sidebarDashboardType == dashboardType.PAYMENT) {

        <app-payment-account-dropdown></app-payment-account-dropdown>

}

      @if (sidebarDashboardType == dashboardType.DEVELOPERS) {

        <app-partner-account-dropdown></app-partner-account-dropdown>

}

      <app-menu-list class="menu-items-container">
        @if (sidebarDashboardType === dashboardType.Payment) {

          Payment Select

}
        @for (key of sidebarData; track $index){
          <app-menu-item [dataSet]="key"></app-menu-item>
        }
      </app-menu-list>
    </app-sidenav>
    <app-sidebar-content>
      <app-top-navbar (toggleSidebar)="toggleSidebarMain()"></app-top-navbar>

      <div class="sidebar-content-container" id="sidebar-container">
        <!-- CONTENT -->
        <router-outlet #outlet="outlet"></router-outlet>
        <!--/ CONTENT -->
      </div>
    </app-sidebar-content>
  </app-sidebar-container>

}
