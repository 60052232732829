import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IApplicationDetail } from '@interfaces/application-details/application-details.interface';
import {
  IEquipmentTerminalDetail,
  IPaymentGatewayList,
} from '@interfaces/equipment-details/equipment-terminal.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import { IMerchantCreateForm } from '@interfaces/mms/merchants/merchant-create-form.interface';
import {
  EMerchantDetailsParamsDefault,
  IMerchantDetailsParams,
  IMerchantDetailsResponse,
  IMerchantGetVarSheetListParams,
  IMerchantVarSheetListResponse,
} from '@interfaces/mms/merchants/merchant-details.interface';
import {
  IMerchantEquipmentParams,
  IMerchantPaymentGatewayResponse,
  IMerchantPhysicalTerminalResponse,
  IMerchantTerminalCalculationResponse,
  IMerchantUpdateEquipments,
  IMerchantUpdatePaymentGateway,
  TMerchantDeleteEquipment,
} from '@interfaces/mms/merchants/merchant-equipment.interface';
import {
  IReportsMerchantKpisParams,
  IReportsMerchantKpisResponse,
} from '@interfaces/mms/merchants/merchant-kpis.interface';
import {
  CReportsMerchantListParamsDefault,
  IReportsMerchantListResponse,
  IReportsMerchantListParams,
} from '@interfaces/mms/merchants/merchant-list.interface';
import {
  CMerchantNotesListParamsDefault,
  ICreateMerchantNoteFormValue,
  IMerchantCreateNoteResponse,
  IMerchantNoteDetailResponse,
  IMerchantNotesListParams,
  IMerchantNotesListResponse,
} from '@interfaces/mms/merchants/merchant-note.interface';
import { IMerchantReservesConfigurationFormValues } from '@interfaces/mms/merchants/merchant-reserves-configuration-form.interface';
import { IMerchantRiskThresholdFormValues } from '@interfaces/mms/merchants/merchant-risk-threshold.interface';
import { IMerchantSettlementReserveFormValues } from '@interfaces/mms/merchants/merchant-settlement-reserve-form.interface';
import {
  IMerchantDocumentUploadResponse,
  IMerchantRequiredDocumentResponse,
  IMerchantUploadParams,
  IMerchantUploadRequiredDocument,
} from '@interfaces/mms/merchants/merchant-upload.interface';
import {
  IAddOfferPricing,
  IAddOfferPricingResponse,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  EMerchantDetailsParams,
  IMerchantUpdateStatusPayload,
} from '@parameters/mms/merchants/merchant-details.parameter';
import {
  CMerchantEquipmentParams,
  EMerchantEquipmentParams,
} from '@parameters/mms/merchants/merchant-equipment.parameter';
import {
  CReportsMerchantKPIsParamsDefault,
  EReportsMerchantKpisParams,
} from '@parameters/mms/merchants/merchant-kpis.parameter';
import { EReportsMerchantListParams } from '@parameters/mms/merchants/merchant-list.parameter';
import { EMerchantNotesListParams } from '@parameters/mms/merchants/merchant-notes.parameter';
import {
  CMerchantUploadParams,
  EMerchantUploadParams,
} from '@parameters/mms/merchants/merchant-upload.parameter';
import {
  EMerchantVarSheetListParams,
  IMerchantCreateVarSheetRequest,
  IMerchantSendVarSheetRequest,
} from '@parameters/mms/merchants/merchant-var-sheet.parameter';
import {
  EBoardingConfigUpdateRequest,
  EDeploymentConfigUpdateRequest,
} from '@parameters/ops-and-uw-tools/deal/deal-details.parameter';
import { Observable } from 'rxjs';
import { UserRoles } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/service';

@Injectable()
export class MerchantMmsV2ClientService {
  public readonly userRoles = UserRoles;

  constructor(
    private readonly _genericClient: GenericClientService,
    private readonly _authenticationService: AuthenticationService
  ) {}

  /** Create Merchant */
  public createMerchant(
    body: IMerchantCreateForm
  ): Observable<IResponseBase<{ merchantId: string }>> {
    const endpoint = EndpointsParameter.MerchantMmsV2;
    return this._genericClient.genericPost<
      IResponseBase<{ merchantId: string }>
    >(endpoint, body);
  }

  /**
   * Get merchant list request
   * @param queryParams
   * @returns
   */
  public getMerchantsList(
    queryParams: IReportsMerchantListParams
  ): Observable<IReportsMerchantListResponse> {
    const endpoint = EndpointsParameter.MerchantMmsV2;
    let params = new HttpParams()
      .set(
        EReportsMerchantListParams.size,
        queryParams[EReportsMerchantListParams.size] ||
          CReportsMerchantListParamsDefault[EReportsMerchantListParams.size]
      )
      .set(
        EReportsMerchantListParams.page,
        queryParams[EReportsMerchantListParams.page] ||
          CReportsMerchantListParamsDefault[EReportsMerchantListParams.page]
      )
      .set(
        EReportsMerchantListParams.sort,
        queryParams[EReportsMerchantListParams.sort]?.join(',')
      )
      .set(
        EReportsMerchantListParams.search,
        queryParams[EReportsMerchantListParams.search] ||
          CReportsMerchantListParamsDefault[EReportsMerchantListParams.search]
      )
      .set(
        EReportsMerchantListParams.status,
        queryParams[EReportsMerchantListParams.status]?.join(',')
      )
      .set(
        EReportsMerchantListParams.relationshipId,
        queryParams[EReportsMerchantListParams.relationshipId]?.join(',')
      )
      .set(
        EReportsMerchantListParams.bins,
        queryParams[EReportsMerchantListParams.bins]?.join(',')
      );

    if (
      queryParams[EReportsMerchantListParams.approvalStartDate] &&
      queryParams[EReportsMerchantListParams.approvalEndDate]
    ) {
      params = params
        .append(
          EReportsMerchantListParams.approvalStartDate,
          queryParams[EReportsMerchantListParams.approvalStartDate] ||
            CReportsMerchantListParamsDefault[
              EReportsMerchantListParams.approvalStartDate
            ]
        )
        .append(
          EReportsMerchantListParams.approvalEndDate,
          queryParams[EReportsMerchantListParams.approvalEndDate] ||
            CReportsMerchantListParamsDefault[
              EReportsMerchantListParams.approvalEndDate
            ]
        );
    }

    if (
      queryParams[EReportsMerchantListParams.closedStartDate] &&
      queryParams[EReportsMerchantListParams.closedEndDate]
    ) {
      params = params
        .append(
          EReportsMerchantListParams.closedStartDate,
          queryParams[EReportsMerchantListParams.closedStartDate] ||
            CReportsMerchantListParamsDefault[
              EReportsMerchantListParams.closedStartDate
            ]
        )
        .append(
          EReportsMerchantListParams.closedEndDate,
          queryParams[EReportsMerchantListParams.closedEndDate] ||
            CReportsMerchantListParamsDefault[
              EReportsMerchantListParams.closedEndDate
            ]
        );
    }

    return this._genericClient.genericGet<IReportsMerchantListResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  /**
   * Get list kpis request
   * @param queryParams
   * @returns
   */
  public getMerchantsKpis(
    queryParams: IReportsMerchantKpisParams
  ): Observable<IReportsMerchantKpisResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsMerchantMmsV2;
    let params = new HttpParams()
      .set(
        EReportsMerchantKpisParams.kpiList,
        queryParams[EReportsMerchantKpisParams.kpiList]?.join(',')
      )
      // .set(
      //   EReportsMerchantKpisParams.mids,
      //   queryParams[EReportsMerchantKpisParams.mids]?.join(',')
      // )
      .set(
        EReportsMerchantKpisParams.relationshipId,
        queryParams[EReportsMerchantKpisParams.mids]?.join(',')
      );
    // .set(
    //   EReportsMerchantKpisParams.bins,
    //   queryParams[EReportsMerchantKpisParams.bins]?.join(',')
    // );

    if (
      queryParams[EReportsMerchantKpisParams.startDate] &&
      queryParams[EReportsMerchantKpisParams.endDate]
    ) {
      params = params
        .append(
          EReportsMerchantKpisParams.startDate,
          queryParams[EReportsMerchantKpisParams.startDate] ||
            CReportsMerchantKPIsParamsDefault[
              EReportsMerchantKpisParams.startDate
            ]
        )
        .append(
          EReportsMerchantKpisParams.endDate,
          queryParams[EReportsMerchantKpisParams.endDate] ||
            CReportsMerchantKPIsParamsDefault[
              EReportsMerchantKpisParams.endDate
            ]
        );
    }

    return this._genericClient.genericGet<IReportsMerchantKpisResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  /**
   * Get Merchant Detail request
   * @param queryParams
   * @returns
   */
  getMerchantDetail(queryParams: IMerchantDetailsParams): Observable<any> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Detail,
      queryParams[EMerchantDetailsParams.id]
    );
    const params = new HttpParams().set(
      EMerchantDetailsParams.filter,
      queryParams[EMerchantDetailsParams.filter] ||
        EMerchantDetailsParamsDefault[EMerchantDetailsParams.filter]
    );

    return this._genericClient.genericGet<IMerchantDetailsResponse>(endpoint, {
      params,
    });
  }

  /**
   * Get Merchant KYC Inquiry Detail
   * @param merchantId
   * @param inquiryId
   * @param groupId
   * @returns
   */
  getMerchantKycInquiryDetail(
    merchantId: string,
    inquiryId: string,
    groupId: string
  ): Observable<any> {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2KycInquiryDetails,
      merchantId
    ).replace('???', inquiryId);
    const params = new HttpParams().set('group_id', groupId || '');
    return this._genericClient.genericGet<IMerchantDetailsResponse>(endpoint, {
      params,
    });
  }

  /**
   * Validate Roles
   * @param role
   * @returns
   */
  public validateRole(role: string) {
    return this._authenticationService.hasRole(role);
  }

  /**
   * Update Merchant Status
   * @param merchantId
   * @param request
   * @returns
   */
  updateMerchantStatus(
    merchantId: string,
    request: IMerchantUpdateStatusPayload
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Status,
      merchantId
    );
    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  /**
   * Get Payment Gateway List
   * @param merchantId
   * @returns
   */
  public getPaymentGatewaysList(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2VirtualGateways,
      merchantId
    );

    return this._genericClient.genericGet<IResponseBase<IPaymentGatewayList[]>>(
      endpoint
    );
  }

  /**
   * Get All Equipment Terminals
   * @param merchantId
   * @returns
   */
  public getEquipmentTerminalsList(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Equipments,
      merchantId
    );

    return this._genericClient.genericGet<IEquipmentTerminalDetail[]>(endpoint);
  }

  /**
   * Get Selected Physical Terminal
   * @param merchantId
   * @param params
   * @param isPaginated
   * @returns
   */
  public getSelectedEquipmentTerminals(
    merchantId: string,
    params: IMerchantEquipmentParams,
    isPaginated: boolean = true
  ): Observable<IMerchantPhysicalTerminalResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedEquipments,
      merchantId
    );

    let httpParams = new HttpParams();
    if (isPaginated) {
      httpParams = httpParams.append(
        EMerchantEquipmentParams.page,
        params?.page || CMerchantEquipmentParams[EMerchantEquipmentParams.page]
      );
      httpParams = httpParams.append(
        EMerchantEquipmentParams.limit,
        params?.limit ||
          CMerchantEquipmentParams[EMerchantEquipmentParams.limit]
      );
    }
    return this._genericClient.genericGet<IMerchantPhysicalTerminalResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Get Selected Payment Gateways
   * @param dealId
   * @returns
   */
  public getSelectedPaymentGateways(
    dealId: string,
    params: IMerchantEquipmentParams
  ): Observable<IMerchantPaymentGatewayResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedVirtualGateways,
      dealId
    );

    const httpParams = new HttpParams()
      .set(
        EMerchantEquipmentParams.page,
        params?.page || CMerchantEquipmentParams[EMerchantEquipmentParams.page]
      )
      .set(
        EMerchantEquipmentParams.limit,
        params?.limit ||
          CMerchantEquipmentParams[EMerchantEquipmentParams.limit]
      );

    return this._genericClient.genericGet<IMerchantPaymentGatewayResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Update Payment Gateway
   * @param payload
   * @param merchantId
   * @returns
   */
  public updatePaymentGateway(
    payload: IMerchantUpdatePaymentGateway,
    merchantId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedVirtualGateways,
      merchantId
    );

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Delete Payment Gateway
   * @param gatewayId
   * @param merchantId
   * @returns
   */
  public deletePaymentGateway(
    gatewayId: string,
    merchantId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedVirtualGateways,
      merchantId
    );

    return this._genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${gatewayId}`
    );
  }

  /**
   * Update Equipment
   * @param payload
   * @param merchantId
   */
  public updateEquipments(
    payload: IMerchantUpdateEquipments,
    merchantId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedEquipments,
      merchantId
    );

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Delete Equipment
   * @param merchantId
   * @param type
   */
  public deleteEquipment(
    merchantId: string,
    pid: string,
    type: TMerchantDeleteEquipment
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2SelectedEquipments,
      merchantId
    );

    const params = new HttpParams().set('type', type);

    return this._genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${pid}`,
      {
        params: params,
      }
    );
  }

  /**
   * Get Terminal Calculations data
   * @param merchantId
   * @returns
   */
  public terminalCalculation(
    merchantId: string
  ): Observable<IMerchantTerminalCalculationResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2TerminalCalculation,
      merchantId
    );
    return this._genericClient.genericGet<IMerchantTerminalCalculationResponse>(
      endpoint
    );
  }

  /**
   * Create/Update Note
   * @param body
   * @param merchantId
   * @returns
   */
  public createNewNote(
    body: ICreateMerchantNoteFormValue,
    merchantId: string,
    isEdit: boolean = false,
    noteId?: string
  ): Observable<IMerchantCreateNoteResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Notes,
      merchantId
    );

    if (isEdit) {
      return this._genericClient.genericPatch<IMerchantCreateNoteResponse>(
        `${endpoint}/${noteId}`,
        body
      );
    } else {
      return this._genericClient.genericPost<IMerchantCreateNoteResponse>(
        endpoint,
        body
      );
    }
  }

  /**
   * Get list of all Notes
   * @param merchantId
   * @returns
   */
  public getNotesList(
    merchantId: string,
    params: IMerchantNotesListParams
  ): Observable<IMerchantNotesListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Notes,
      merchantId
    );
    const httpParams = new HttpParams()
      .set(
        EMerchantNotesListParams.page,
        params[EMerchantNotesListParams.page] ||
          CMerchantNotesListParamsDefault[EMerchantNotesListParams.page]
      )
      .set(
        EMerchantNotesListParams.limit,
        params[EMerchantNotesListParams.limit] ||
          CMerchantNotesListParamsDefault[EMerchantNotesListParams.limit]
      )
      .set(
        EMerchantNotesListParams.search,
        params[EMerchantNotesListParams.search] ||
          CMerchantNotesListParamsDefault[EMerchantNotesListParams.search]
      )
      .set(
        EMerchantNotesListParams.sort,
        params[EMerchantNotesListParams.sort]?.join(',') || ''
      );
    return this._genericClient.genericGet<IMerchantNotesListResponse>(
      endpoint,
      {
        params: httpParams,
      }
    );
  }

  /**
   * Get detail of single Note
   * @param merchantId
   * @param noteId
   * @returns
   */
  public getNotesDetail(
    dealId: string,
    noteId: string
  ): Observable<IMerchantNoteDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Notes,
      dealId
    );
    return this._genericClient.genericGet<IMerchantNoteDetailResponse>(
      `${endpoint}/${noteId}`
    );
  }

  /**
   * Delete existing note
   * @param merchantId
   * @param noteId
   * @returns
   */
  public deleteNote(
    merchantId: string,
    noteId: string
  ): Observable<IMerchantNoteDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Notes,
      merchantId
    );
    return this._genericClient.genericDelete<IMerchantNoteDetailResponse>(
      `${endpoint}/${noteId}`
    );
  }

  /**
   * Update Merchant Application
   * @param merchantId
   * @param data
   * @returns
   */
  updateMerchantApplication(
    merchantId: string,
    data: IApplicationDetail
  ): Observable<IResponseBase<{ dealsId: string }>> {
    const endpoint = `${EndpointsParameter.MerchantMmsV2}/${merchantId}`;
    return this._genericClient.genericPut<IResponseBase<null>>(endpoint, data);
  }

  /**
   * Add Update Pricing Information
   * @param data
   * @param merchantId
   * @param isEdit
   * @returns
   */
  public updateOfferPricing(
    data: IAddOfferPricing,
    merchantId: string,
    isEdit: boolean = false
  ): Observable<IAddOfferPricingResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2OfferPricing,
      merchantId
    );
    if (isEdit) {
      return this._genericClient.genericPatch<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    } else {
      return this._genericClient.genericPost<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    }
  }

  /**
   * Get list of required documents
   * @param merchantId
   * @param params
   * @returns
   */
  public getRequiredDocuments(
    merchantId: string,
    params: { search: string }
  ): Observable<IMerchantRequiredDocumentResponse> {
    const param = new HttpParams().set('search', params?.search || '');

    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2RequiredDocument,
      merchantId
    );

    return this._genericClient.genericGet<IMerchantRequiredDocumentResponse>(
      endpoint,
      { params: param }
    );
  }

  /**
   * Get Uploaded documents
   * @param merchantId
   * @param params
   * @returns
   */
  public getDocumentUploads(
    merchantId: string,
    params: IMerchantUploadParams
  ): Observable<IMerchantDocumentUploadResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2DocumentUploads,
      merchantId
    );

    let param = new HttpParams()
      .set(
        EMerchantUploadParams.page,
        params[EMerchantUploadParams.page] ||
          CMerchantUploadParams[EMerchantUploadParams.page]
      )
      .set(
        EMerchantUploadParams.limit,
        params[EMerchantUploadParams.limit] ||
          CMerchantUploadParams[EMerchantUploadParams.limit]
      )
      .set(
        EMerchantUploadParams.search,
        params[EMerchantUploadParams.search] ||
          CMerchantUploadParams[EMerchantUploadParams.search]
      )
      .set(
        EMerchantUploadParams.sort,
        params[EMerchantUploadParams.sort].join(',') ||
          CMerchantUploadParams[EMerchantUploadParams.sort].join(',')
      );

    return this._genericClient.genericGet<IMerchantDocumentUploadResponse>(
      endpoint,
      { params: param }
    );
  }

  /**
   * Upload required documents
   * @param docs
   * @param merchantId
   * @returns
   */
  public uploadRequiredDocuments(
    docs: IMerchantUploadRequiredDocument,
    merchantId: string
  ): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2UploadDocuments,
      merchantId
    );

    return this._genericClient.genericPost<IResponseBase<void>>(endpoint, docs);
  }

  /**
   * Delete Document
   * @param merchantId
   * @param documentId
   * @returns
   */
  public deleteRequiredDocument(
    merchantId: string,
    documentId: string
  ): Observable<IResponseBase<void>> {
    const url = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2UploadDocuments,
      merchantId
    );
    const endpoint = `${url}/${documentId}`;
    return this._genericClient.genericDelete(endpoint);
  }

  /**
   * Update Boarding Configuration
   * @param merchantId
   * @param request
   * @returns
   */
  updateBoardingConfiguration(
    merchantId: string,
    request: EBoardingConfigUpdateRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2BoardingConfiguration,
      merchantId
    );
    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  /**
   * Update Deployment Configuration
   * @param merchantId
   * @param request
   * @returns
   */
  updateDeploymentConfiguration(
    merchantId: string,
    request: EDeploymentConfigUpdateRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2DeploymentConfiguration,
      merchantId
    );
    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  /**
   * Post Create Varsheet Item
   * @param merchantId
   * @param request
   * @returns
   */
  postCreateVarSheet(
    merchantId: string,
    request: IMerchantCreateVarSheetRequest
  ) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2VarSheet,
      merchantId
    );

    return this._genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  /**
   * Varsheet List
   * @param queryParams
   * @returns
   */
  getMerchantVarSheetList(queryParams: IMerchantGetVarSheetListParams) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2VarSheet,
      queryParams[EMerchantVarSheetListParams.merchantId]
    );

    return this._genericClient.genericGet<IMerchantVarSheetListResponse>(
      endpoint
    );
  }

  /**
   * Delete Varsheet
   * @param merchantId
   * @param varsheetId
   * @returns
   */
  deleteVarsheet(merchantId: string, varsheetId: string) {
    let endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2VarSheet,
      merchantId
    )}/${varsheetId}`;
    return this._genericClient.genericDelete<IResponseBase<null>>(endpoint);
  }

  /**
   * Send Var Sheet
   * @param merchantId
   * @param varsheetId
   * @param request
   * @returns
   */
  sendVarSheet(
    merchantId: string,
    varsheetId: string,
    request: IMerchantSendVarSheetRequest
  ) {
    let endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2VarSheet,
      merchantId
    )}/${varsheetId}`;

    return this._genericClient.genericPut<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  /**
   * Patch Settlement Configurations
   * @param payload
   * @param merchantId
   * @returns
   */
  public patchSettlementConfigurations(
    payload: IMerchantSettlementReserveFormValues,
    merchantId: string
  ) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Settlement,
      merchantId
    )}`;

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Patch Reserves Configurations
   * @param payload
   * @param merchantId
   * @returns
   */
  public patchReservesConfigurations(
    payload: IMerchantReservesConfigurationFormValues,
    merchantId: string
  ) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2Reserves,
      merchantId
    )}`;

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Post Risk Monitoring Configurations
   * @param payload
   * @param merchantId
   * @returns
   */
  public postRiskMonitoringConfigurations(
    payload: IMerchantRiskThresholdFormValues,
    merchantId: string
  ) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2RiskMonitoring,
      merchantId
    )}`;

    return this._genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Patch Risk Monitoring Configurations
   * @param payload
   * @param merchantId
   * @returns
   */
  public patchRiskMonitoringConfigurations(
    payload: IMerchantRiskThresholdFormValues,
    merchantId: string
  ) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2RiskMonitoring,
      merchantId
    )}`;

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Get Risk Monitoring Configurations
   * @param merchantId
   * @returns
   */
  public getRiskMonitoringConfigurations(merchantId: string) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantMmsV2RiskMonitoring,
      merchantId
    )}`;

    return this._genericClient.genericGet<
      IResponseBase<IMerchantRiskThresholdFormValues>
    >(endpoint);
  }
}
