export interface IMerchantCreateVarSheetRequest {
  vNumber: string;
  description: string;
  terminalType: string;
  uploadId: string;
}

export const enum EMerchantVarSheetListParams {
  merchantId = 'merchantId',
}

export interface IMerchantSendVarSheetRequest {
  name: string;
  email_template: string;
  sendTo: string[];
}

export enum EMerchantVarsheetTerminalType {
  Edgepay = 'edgepay',
  AuthNet = 'auth_net',
  Nmi = 'nmi',
}

export enum EMerchantVarsheetTerminalTypeName {
  Edgepay = 'EdgePay',
  AuthNet = 'Authorize.NET Gateway',
  Nmi = 'NMI',
}

export const CMerchantVarsheetTerminalList = [
  {
    value: EMerchantVarsheetTerminalType.Edgepay,
    name: EMerchantVarsheetTerminalTypeName.Edgepay,
  },
  {
    value: EMerchantVarsheetTerminalType.AuthNet,
    name: EMerchantVarsheetTerminalTypeName.AuthNet,
  },
  {
    value: EMerchantVarsheetTerminalType.Nmi,
    name: EMerchantVarsheetTerminalTypeName.Nmi,
  },
];

export enum EMerchantVarsheetEmailTemplate {
  AuthNetNew = 'auth_net_new',
  AuthNetExisting = 'auth_net_existing',
  Terminal = 'terminal',
  NmiNew = 'nmi_new',
  NmiExisting = 'nmi_existing',
  Other = 'other',
}

export const CMerchantVarsheetEmailTemplateName = {
  [EMerchantVarsheetEmailTemplate.AuthNetNew]: 'New Auth.NET Deployment',
  [EMerchantVarsheetEmailTemplate.AuthNetExisting]:
    'Existing Auth.NET Deployment',
  [EMerchantVarsheetEmailTemplate.Terminal]: 'Terminal',
  [EMerchantVarsheetEmailTemplate.NmiNew]: 'New NMI Deployment',
  [EMerchantVarsheetEmailTemplate.NmiExisting]: 'Existing NMI Deployment',
  [EMerchantVarsheetEmailTemplate.Other]: 'Other',
};

export const CMerchantVarsheetEmailTemplateList = [
  {
    value: EMerchantVarsheetEmailTemplate.AuthNetExisting,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.AuthNetExisting
    ],
  },
  {
    value: EMerchantVarsheetEmailTemplate.AuthNetNew,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.AuthNetNew
    ],
  },
  {
    value: EMerchantVarsheetEmailTemplate.Terminal,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.Terminal
    ],
  },
  {
    value: EMerchantVarsheetEmailTemplate.NmiNew,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.NmiNew
    ],
  },
  {
    value: EMerchantVarsheetEmailTemplate.NmiExisting,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.NmiExisting
    ],
  },
  {
    value: EMerchantVarsheetEmailTemplate.Other,
    name: CMerchantVarsheetEmailTemplateName[
      EMerchantVarsheetEmailTemplate.Other
    ],
  },
];
