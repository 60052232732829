import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { NavProfileComponent } from './nav-profile/nav-profile.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { GtrBadgeComponent, GtrIconComponent } from '@gettrx/core-components-angular';

@NgModule({
  declarations: [TopNavbarComponent, NavProfileComponent],
  imports: [
    RouterModule,
    CommonModule,
    CorePipesModule,
    GtrIconComponent,
    GtrBadgeComponent,
  ],
  providers: [],
  exports: [TopNavbarComponent, NavProfileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TopNavbarModule {}
