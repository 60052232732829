import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EMerchantStatus,
  EReportsMerchantListParams,
  EReportsMerchantListSort,
} from '@parameters/mms/merchants/merchant-list.parameter';

export interface IReportsMerchantListParams {
  [EReportsMerchantListParams.size]: number;
  [EReportsMerchantListParams.page]: number;
  [EReportsMerchantListParams.sort]: EReportsMerchantListSort[];
  [EReportsMerchantListParams.search]: string;
  [EReportsMerchantListParams.approvalStartDate]: number;
  [EReportsMerchantListParams.approvalEndDate]: number;
  [EReportsMerchantListParams.closedStartDate]: number;
  [EReportsMerchantListParams.closedEndDate]: number;
  [EReportsMerchantListParams.status]: EMerchantStatus[];
  [EReportsMerchantListParams.relationshipId]: string[];
  [EReportsMerchantListParams.bins]: string[];
}

export const CReportsMerchantListParamsDefault: IReportsMerchantListParams = {
  [EReportsMerchantListParams.size]: 10,
  [EReportsMerchantListParams.page]: 1,
  [EReportsMerchantListParams.sort]: [
    EReportsMerchantListSort.approvalDateDesc,
  ],
  [EReportsMerchantListParams.search]: '',
  [EReportsMerchantListParams.approvalStartDate]: null,
  [EReportsMerchantListParams.approvalEndDate]: null,
  [EReportsMerchantListParams.closedStartDate]: null,
  [EReportsMerchantListParams.closedEndDate]: null,
  [EReportsMerchantListParams.status]: [],
  [EReportsMerchantListParams.relationshipId]: [],
  [EReportsMerchantListParams.bins]: [],
};

export interface IReportsMerchant {
  dbaName: string;
  id: string;
  legalName: string;
  mid: string;
  primaryContactEmail: string;
  primaryContactName: string;
  relationshipGettrxAgentId: string;
  relationshipName: string;
  status: EMerchantStatus;
  type: string;
  uwApprovalDatetime: string;
  uwClosedDatetime: string;
}

export interface IReportsMerchantListResponse
  extends IResponseBase<
    IResponseData<IReportsMerchant> & {
      other: { totalActiveMerchants: number };
    }
  > {}
