@import "src/styles/base/core/menu/menu-types/vertical-menu.scss";
@import "src/styles/base/core/menu/menu-types/vertical-overlay-menu.scss";

.sidebar-content-container {
  margin: 0px 24px 0px 27px;
  margin-top: 108px;
}

.sidebar-content-container.add-overlay {
  z-index: 9999;
  position: relative;
}

@media only screen and (max-width: 692px) {
  .sidebar-content-container {
    margin: 0px 16px 0px 15px;
    margin-top: 108px;
  }
}

@media only screen and (max-width: 399px) {
  .sidebar-content-container {
    margin: 0px 7px 0px 7px;
    margin-top: 108px;
  }
}

app-top-navbar {
  position: fixed;
  z-index: 99;
  right: 0px;
  left: 0px;
  transition: all 0.4s ease-in-out;
}
app-top-navbar.sidebar-expanded {
  position: fixed;
  right: 0px;
  left: 263px;
  transition: all 0.4s ease-in-out;
}

.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0.5rem 1rem;
}
