import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { ApmUserService } from '@stores/apm-user.service';
import { apmInstance } from './apm.config';
import {
  ApmErrorHandler,
  ApmModule,
  ApmService,
} from '@elastic/apm-rum-angular';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { AmountFormatter } from './core/pipes';
import { HttpsService } from './shared/services/base.service';
import { CommonService } from './shared/services/common.service';
import { apiList } from '@helpers/apilist';
import { CookieService } from 'ngx-cookie-service';
import { GlobalToastrService } from './shared/services/toastr.service';
import { BrandingService } from './shared/services/branding.service';
import {
  DashBoardResolveService,
  StepResolveService,
} from './auth/helpers/step.resolve.guard';
import { ToasterService } from './shared/services/toaster.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { environment } from '@environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoadingInterceptor } from '@helpers/loader.interceptor';
import { ErrorInterceptor, JwtInterceptor } from './auth/helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppFactoryModule } from './app-factory.module';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '@core/core.module';
import { coreConfig } from './app-config';
import { CoreSidebarModule } from '@core/components/core-sidebar/core-sidebar.module';

export function startupServiceFactory(
  BrandingService: BrandingService
): Function {
  return () => BrandingService.load();
}

export function initializeApm(apmUserService: ApmUserService) {
  return () => apmUserService.setUserContext();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      ApmModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppFactoryModule.forRoot(),

      //NgBootstrap
      ToastrModule.forRoot({
        timeOut: 4000,
      }),

      // Core modules
      CoreModule.forRoot(coreConfig),
      CoreSidebarModule,

      // App modules
      RecaptchaV3Module
    ),
    ApmUserService,
    { provide: ApmService, useValue: apmInstance },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApm,
      deps: [ApmUserService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    Title,
    DatePipe,
    CurrencyPipe,
    AmountFormatter,
    TitleCasePipe,
    HttpsService,
    CommonService,
    apiList,
    CookieService,
    GlobalToastrService,
    BrandingService,
    StepResolveService,
    DashBoardResolveService,
    ToasterService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory, // future used code block
      deps: [BrandingService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
};
