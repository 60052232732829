export const enum EMerchantUploadParams {
  limit = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EMerchantUploadParamSort {
  documentNameAsc = 'file_name:asc',
  documentNameDesc = 'file_name:desc',
  documentTypeAsc = 'documentType:asc',
  documentTypeDesc = 'documentType:desc',
  uploadedDateAsc = 'created_at:asc',
  uploadedDateDesc = 'created_at:desc',
}

export const CMerchantUploadParams = {
  [EMerchantUploadParams.limit]: 10,
  [EMerchantUploadParams.page]: 1,
  [EMerchantUploadParams.sort]: [],
  [EMerchantUploadParams.search]: '',
};
