import { IReportsMerchantKpisParams } from '@interfaces/mms/merchants/merchant-kpis.interface';
import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export enum EReportsMerchantKpisParams {
  startDate = 'start_date',
  endDate = 'end_date',
  relationshipId = 'relationship_ids',
  kpiList = 'kpis',
  mids = 'mids',
  bins = 'bins',
}

export enum EReportsMerchantKpisList {
  newMerchants = 'new_merchants',
  closedMerchants = 'closed_merchants',
}

export const CReportsMerchantKPIsParamsDefault: IReportsMerchantKpisParams = {
  [EReportsMerchantKpisParams.startDate]:
    CDateFilterListValue[EDateFilter.last30Days].unixValue.startDate,
  [EReportsMerchantKpisParams.endDate]:
    CDateFilterListValue[EDateFilter.last30Days].unixValue.endDate,
  [EReportsMerchantKpisParams.kpiList]: [
    EReportsMerchantKpisList.newMerchants,
    EReportsMerchantKpisList.closedMerchants,
  ],
  [EReportsMerchantKpisParams.relationshipId]: [],
  [EReportsMerchantKpisParams.mids]: [],
  [EReportsMerchantKpisParams.bins]: [],
};
