import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreSidebarModule } from '@core/components/core-sidebar/core-sidebar.module';
import { ContentModule } from '../components/content/content.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { TopNavbarModule } from '../components/top-navbar/top-navbar.module';
import { VerticalLayoutComponent } from './vertical-layout.component';
import { SidebarModule } from '../../sidebar/sidebar.module';
import { PaymentAccountDropdownModule } from '@atoms/payment-account-dropdown/payment-account-dropdown.module';
import { PartnerAccountDropdownModule } from '@atoms/partner-account-dropdown/partner-account-dropdown.module';
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [VerticalLayoutComponent],
  imports: [
    RouterModule,
    CommonModule,
    CoreSidebarModule,
    NavbarModule,
    ContentModule,
    TopNavbarModule,
    SidebarModule,
    PaymentAccountDropdownModule,
    PartnerAccountDropdownModule,
  ],
  exports: [VerticalLayoutComponent],
})
export class VerticalLayoutModule {}
