import {
  CDealMerchantTypeEmpty,
  CDealMerchantTypeName,
  EDealMerchantType,
  TDealMerchantType,
} from '@parameters/ops-and-uw-tools/deal/deal-details.parameter';

export const enum EMerchantDetailsParams {
  id = 'id',
  filter = 'filter',
}

export interface EBoardingConfigUpdateRequest {
  merchantType: string;
  parentPayfacId: string;
  mid: string;
  discoverAccountId: string;
  mcc: string;
  associationNumberOverride: number;
  amexWorkaroundMid: string;
}

export interface EDeploymentConfigUpdateRequest {
  backendType: string;
  bank: string;
}

export interface EMerchantUpdateStatusRequest {
  status: string;
}

export const CMerchantTypeList: TDealMerchantType[] = [
  {
    value: EDealMerchantType.Direct,
    name: CDealMerchantTypeName.Direct,
  },
  {
    value: EDealMerchantType.Sub,
    name: CDealMerchantTypeName.Sub,
  },
  {
    value: EDealMerchantType.Payfac,
    name: CDealMerchantTypeName.Payfac,
  },
];

export enum EnumVarsheetTerminalType {
  Edgepay = 'edgepay',
  AuthNet = 'auth_net',
  Nmi = 'nmi',
}

export enum EnumVarsheetTerminalTypeName {
  Edgepay = 'EdgePay',
  AuthNet = 'Authorize.NET Gateway',
  Nmi = 'NMI',
}

export const CVarsheetTerminalList = [
  {
    value: EnumVarsheetTerminalType.Edgepay,
    name: EnumVarsheetTerminalTypeName.Edgepay,
  },
  {
    value: EnumVarsheetTerminalType.AuthNet,
    name: EnumVarsheetTerminalTypeName.AuthNet,
  },
  {
    value: EnumVarsheetTerminalType.Nmi,
    name: EnumVarsheetTerminalTypeName.Nmi,
  },
];

export const CMerchantTypeListExtended: TDealMerchantType[] = [
  ...CMerchantTypeList,
  CDealMerchantTypeEmpty,
];

export const enum EDealBackend {
  Gettrx = 'gettrx',
  Tsys = 'tsys',
  Empty = '',
}

export const enum EDealBackendName {
  Gettrx = 'GETTRX',
  Tsys = 'TSYS',
  Empty = '',
}

export const CBackendEmpty = {
  value: EDealBackend.Empty,
  name: EDealBackendName.Empty,
};

export const CBankEmpty = {
  value: EDealBank.Empty,
  name: EDealBankName.Empty,
};

export const CBankFirstPremier = {
  value: EDealBank.FirstPremier,
  name: EDealBankName.FirstPremier,
};

export type TDealBackendType = {
  value: EDealBackend;
  name: EDealBackendName;
};

export const CDealBackendList: TDealBackendType[] = [
  {
    value: EDealBackend.Gettrx,
    name: EDealBackendName.Gettrx,
  },
  {
    value: EDealBackend.Tsys,
    name: EDealBackendName.Tsys,
  },
];

export const CDealBackendListExtended: TDealBackendType[] = [
  ...CDealBackendList,
  CBackendEmpty,
];

export const enum EDealBank {
  Esquire = 'esquire',
  Merrick = 'merrick',
  FirstFresno = 'first_fresno',
  FirstPremier = 'first_premier',
  Empty = '',
}

export const enum EDealBankName {
  Esquire = 'Esquire',
  Merrick = 'Merrick',
  FirstFresno = 'First Fresno',
  FirstPremier = 'First Premier',
  Empty = '',
}

export type TDealBankType = {
  value: EDealBank;
  name: EDealBankName;
};

export const CDealBankList: TDealBankType[] = [
  {
    value: EDealBank.Esquire,
    name: EDealBankName.Esquire,
  },
  {
    value: EDealBank.Merrick,
    name: EDealBankName.Merrick,
  },
  // {
  //   value: EDealBank.FirstFresno,
  //   name: EDealBankName.FirstFresno,
  // },
];

export const CDealAchBankList: TDealBankType[] = [CBankFirstPremier];

export const CDealBankListExtended: TDealBankType[] = [
  ...CDealBankList,
  CBankFirstPremier,
  CBankEmpty,
];

export const enum EAutomationListParams {
  merchantId = 'merchantId',
}

export const enum EGetPendindDocumentsParams {
  merchantId = 'merchantId',
}

('Invalid email template - Email template must be one of these - auth_net_new,auth_net_existing,terminal,nmi_new,nmi_existing,other');

export enum EVarsheetEmailTemplate {
  AuthNetNew = 'auth_net_new',
  AuthNetExisting = 'auth_net_existing',
  Terminal = 'terminal',
  NmiNew = 'nmi_new',
  NmiExisting = 'nmi_existing',
  Other = 'other',
}

export enum EVarsheetEmailTemplateName {
  AuthNetNew = 'New Auth.NET Deployment',
  AuthNetExisting = 'Existing Auth.NET Deployment',
  Terminal = 'Terminal',
  NmiNew = 'New NMI Deployment',
  NmiExisting = 'Existing NMI Deployment',
  Other = 'Other',
}

export const CVarsheetEmailTemplateList = [
  {
    value: EVarsheetEmailTemplate.AuthNetExisting,
    name: EVarsheetEmailTemplateName.AuthNetExisting,
  },
  {
    value: EVarsheetEmailTemplate.AuthNetNew,
    name: EVarsheetEmailTemplateName.AuthNetNew,
  },
  {
    value: EVarsheetEmailTemplate.Terminal,
    name: EVarsheetEmailTemplateName.Terminal,
  },
  {
    value: EVarsheetEmailTemplate.NmiNew,
    name: EVarsheetEmailTemplateName.NmiNew,
  },
  {
    value: EVarsheetEmailTemplate.NmiExisting,
    name: EVarsheetEmailTemplateName.NmiExisting,
  },
  {
    value: EVarsheetEmailTemplate.Other,
    name: EVarsheetEmailTemplateName.Other,
  },
];

export enum EClosedMerchantCause {
  Voluntary = 'voluntary',
  Involuntary = 'involuntary',
}

export const CClosedMerchantCause = [
  { label: 'Voluntary', value: EClosedMerchantCause.Voluntary },
  { label: 'Involuntary', value: EClosedMerchantCause.Involuntary },
];

export enum EClosedMerchantReasonVolutary {
  Bankruptcy = 'Bankruptcy',
  BusinessSold = 'Business sold',
  Consolidation = 'Consolidation',
  CostEffectiveness = 'Cost effectiveness',
  Equipment = 'Equipment',
  NewMid = 'New MID',
  NoLongerInBusiness = 'No Longer in Business',
  NotReadyToProcess = 'Not ready to process',
  Pricing = 'Pricing',
  UnhappyWithServices = 'Unhappy with services',
}

export enum EClosedMerchantReasonInvoluntary {
  AchRejects = 'ACH rejects',
  AchRejectsNonProcessingAccount = 'ACH Rejects (non-processing account)',
  BreachOfMerchantAgreement = 'Breach of merchant agreement',
  CompromisedAccount = 'Compromised Account',
  Credit = 'Credit',
  Decesased = 'Deceased',
  InconsistentInfo = 'Inconsistent Info',
  Match = 'MATCH',
  OutsideOfGuidelines = 'Outside of guidelines',
  RiskCollusiveMerchant = 'Risk - Collusive merchant',
  RiskExcessiveChargebacks = 'Risk - Excessive Chargebacks',
  RiskFraud = 'Risk - Fraud',
  RiskSuspiciousActivity = 'Risk - Suspicious activity',
  ClosureRequest = 'V/MC closure request',
}

export interface IMerchantUpdateStatusPayload {
  status: string;
  closedMerchantCause: EClosedMerchantCause;
  closedMerchantReason:
    | EClosedMerchantReasonVolutary
    | EClosedMerchantReasonInvoluntary;
  notes: string;
  closedDateTime: string;
}
