import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-om-perfect-scrollbar';

import { NavbarComponent } from './navbar.component';
import {FeatherIconDirective} from "../../../../directives/core-feather-icons";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [NavbarComponent],
    imports: [RouterModule, PerfectScrollbarModule, FeatherIconDirective],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
