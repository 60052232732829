import { FormControl } from '@angular/forms';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EMerchantNotesListParams,
  EMerchantNotesListParamSort,
} from '@parameters/mms/merchants/merchant-notes.parameter';

export interface IMerchantNote {
  body: string;
  createdBy: string;
  dateTime: Date | string;
  email: string;
  id: string;
  title: string;
}

export interface IMerchantCreateNoteResponse
  extends IResponseBase<{ noteId: string }> {}

export interface IMerchantNoteDetailResponse
  extends IResponseBase<IMerchantNote> {}

export interface IMerchantNotesListResponse
  extends IResponseBase<IResponseData<IMerchantNote>> {}

export interface IMerchantNotesListParams {
  [EMerchantNotesListParams.limit]: number;
  [EMerchantNotesListParams.page]: number;
  [EMerchantNotesListParams.sort]: EMerchantNotesListParamSort[];
  [EMerchantNotesListParams.search]: string;
}

export const CMerchantNotesListParamsDefault: IMerchantNotesListParams = {
  [EMerchantNotesListParams.limit]: 10,
  [EMerchantNotesListParams.page]: 1,
  [EMerchantNotesListParams.sort]: [
    EMerchantNotesListParamSort.createdDateDesc,
  ],
  [EMerchantNotesListParams.search]: '',
};

export enum ECreateMerchantNoteFormKeys {
  title = 'title',
  body = 'body',
}

export interface ICreateMerchantNoteFormValue {
  [ECreateMerchantNoteFormKeys.title]: string;
  [ECreateMerchantNoteFormKeys.body]: string;
}

export interface ICreateMerchantNoteForm {
  [ECreateMerchantNoteFormKeys.title]: FormControl<
    ICreateMerchantNoteFormValue[ECreateMerchantNoteFormKeys.title]
  >;
  [ECreateMerchantNoteFormKeys.body]: FormControl<
    ICreateMerchantNoteFormValue[ECreateMerchantNoteFormKeys.body]
  >;
}

export const CCreateMerchantNoteFormValue = {
  [ECreateMerchantNoteFormKeys.title]: '',
  [ECreateMerchantNoteFormKeys.body]: '',
};
