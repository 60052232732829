import { Pipe, PipeTransform } from '@angular/core';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'extractExtension',
})
export class FileExtension implements PipeTransform {
  /**
   * Pipe Constructor
   */

  constructor() {}

  /**
   * Transform
   *
   * @param value: string
   * @param type: string
   */
  transform(fileName: string): string {
    const extension = fileName.split('.').pop();
    return extension ? extension.toLowerCase() : null;
  }
}
