import {
  IApplicationDetail,
  IApplicationDetailAchProcessingInfo,
  IApplicationDetailBankingInformation,
  IApplicationDetailBusinessPrincipals,
  IApplicationDetailCardBrandElections,
  IApplicationDetailCoreBusinessInfo,
  IApplicationDetailMotoQuestionnaire,
  IApplicationDetailPaymentMethod,
  IApplicationDetailProcessingInformation,
  IApplicationDetailSecurityInformation,
  IApplicationDetailSignatureInformation,
} from '@interfaces/application-details/application-details.interface';
import { IEntityRelationship } from '@interfaces/entity/relationship/entity-relationship.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IKycBankValidationDetail,
  IKycDetail,
  IKycExperianPrincipalDetail,
  IKycMatchInquiryDetail,
  IKycMatchLookupDetail,
  IKycOfacBusinessDetail,
  IKycOfacControllingDetail,
  IKycOfacPrincipalDetail,
  IKycSiteScanDetail,
  IKycTinCheckBusinessDetail,
  IKycTinCheckPrincipalDetail,
} from '@interfaces/kyc-details/kyc-details.interface';
import { IOfferPricingPlanData } from '@interfaces/offer-pricing/offer-pricing.interface';
import { EMerchantDetailsParams } from '@parameters/mms/merchants/merchant-details.parameter';
import { EMerchantStatus } from '@parameters/mms/merchants/merchant-list.parameter';
import { EMerchantVarSheetListParams } from '@parameters/mms/merchants/merchant-var-sheet.parameter';
import {
  EAutomationListParams,
  EDealMerchantType,
  EGetPendindDocumentsParams,
  EDealBackend,
  EDealBank,
} from '@parameters/ops-and-uw-tools/deal/deal-details.parameter';
import { IMerchantReservesConfigurationFormValues } from './merchant-reserves-configuration-form.interface';
import { IMerchantSettlementReserveFormValues } from './merchant-settlement-reserve-form.interface';

export interface IMerchantDetails {
  merchantSummary: IMerchantDetailSummary;
  coreBusinessInfo: IApplicationDetailCoreBusinessInfo;
  processingInformation: IApplicationDetailProcessingInformation;
  achProcessingInformation: IApplicationDetailAchProcessingInfo;
  businessPrincipals: IApplicationDetailBusinessPrincipals[];
  agreementAcceptances: IApplicationDetailSignatureInformation;
  paymentMethods: IApplicationDetailPaymentMethod;
  motoQuestionnaire: IApplicationDetailMotoQuestionnaire;
  bankingInformation: IApplicationDetailBankingInformation;
  securityInfo: IApplicationDetailSecurityInformation;
  offerInfo: IOfferPricingPlanData;
  cardBrandElections: IApplicationDetailCardBrandElections;
  reservesConfigurations: IMerchantReservesConfigurationFormValues;
  settlementConfigurations: IMerchantSettlementReserveFormValues;
}

export interface loadingInfo {
  merchantLoadingInfo: boolean;
  applicationLoadingInfo: boolean;
  automatioonLoadingInfo: boolean;
}

export interface IMerchantDetailSummary {
  id: string;
  legalBusinessName: string;
  businessNameDBA: string;
  status: EMerchantStatus;
  specialNotes: string;
  relationship: IEntityRelationship;
  pricingPlanId: string;
  isOfferSent: boolean;
  autoSubmitDeal: boolean;
  mid: string;
  secondaryMid: string;
  accountId: string;
  externalReferenceId: string;
  dealId: string;
  merchantType: EDealMerchantType;
  payfacTerminalId: string;
  payfacId: string;
  dealCode: string;
  sfLink: string;
  processorBusinessName: string;
  processorLegalBusinessName: string;
  discoverAccountNumber: string;
  amexWorkaroundMid: string;
  associationNumberOverride: string;
  mccCode: string;
  backend: EDealBackend;
  bank: EDealBank;
  submittedDate: string;
  resolutionDate: string;
  approvedDate: string;
  resolution: string;
  primaryContact: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  aexLink: string;
}

export interface IMerchantDetailsResponse
  extends IResponseBase<IMerchantDetails> {}

export interface IMerchantDetailsParams {
  [EMerchantDetailsParams.filter]?: string;
  [EMerchantDetailsParams.id]: string;
}

export const EMerchantDetailsParamsDefault: IMerchantDetailsParams = {
  [EMerchantDetailsParams.filter]:
    'merchantSummary,primaryContact,coreBusinessInfo,processingInformation,businessPrincipals,paymentMethods,motoQuestionnaire,bankingInformation,offer_info,cardBrandElections,settlementConfigurations,reservesConfigurations,securityInfo',
  [EMerchantDetailsParams.id]: '',
};

export interface IAutomationListParams {
  [EAutomationListParams.merchantId]: string;
}

export interface IGetPendindDocumentsParams {
  [EGetPendindDocumentsParams.merchantId]: string;
}

export interface IMerchantGetVarSheetListParams {
  [EMerchantVarSheetListParams.merchantId]: string;
}

export interface IMerchantAutomationItem {
  taskId: string;
  title: string;
  description: string;
  taskType: EnumAutomationTaskType;
  currentStatus: EnumAutomationStatus;
  taskLogs: ITaskLog[];
  errorLogs: string[];
}

export interface IMerchantVarsheetItem {
  varsheetId: string;
  terminalType: string;
  vNumber: string;
  createdOn: string;
  createdBy: string;
}

export interface IMerchantVarsheetList {
  varsheetList: IMerchantVarsheetItem[];
}

export interface IMerchantPendingDoc {
  description: string;
  id: string;
}

export interface IMerchantPendingDocument {
  merchantPendingDocs: IMerchantPendingDoc[];
}

export interface ITaskLog {
  description: string;
  createdDate: string;
  status: EnumAutomationTaskLogStatus;
}

export enum EnumAutomationTaskLogStatus {
  CreatedAutomatically = 'created_automatically',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed',
  Skipped = 'skipped',
  // from the response
  CompletedManualIntervention = 'completed_manual_intervention',
  Created = 'created',
}

export enum EnumAutomationTaskType {
  SalesforceIntegration = 'salesforce_integration',
  MerchantScoring = 'merchant_scoring',
  DataValidation = 'data_validation',
  LinkMerchant = 'link_merchant',
  BoardToMms = 'board_to_mms',
  BoardToTsys = 'board_to_tsys',
  BoardToPpm = 'board_to_ppm',
  UpdateStatusToBoarded = 'update_status_to_boarded',
  CreateVarTerminal = 'create_var_terminal',
  SendVarSheet = 'send_var_sheet',
  UpdateStatusToCompleted = 'update_status_to_deployed',
}

export enum EnumAutomationStatus {
  Completed = 'completed', // hide
  InProgress = 'in_progress', // hide
  Failed = 'failed',
  NotStarted = 'not_started',
}

export const CAutomationStatusColor = {
  [EnumAutomationStatus.Completed]: 'success',
  [EnumAutomationStatus.InProgress]: 'secondary',
  [EnumAutomationStatus.Failed]: 'danger',
  [EnumAutomationStatus.NotStarted]: 'dark',
};

export const CAutomationStatusName = {
  [EnumAutomationStatus.Completed]: 'Completed',
  [EnumAutomationStatus.InProgress]: 'In Progress',
  [EnumAutomationStatus.Failed]: 'Failed',
  [EnumAutomationStatus.NotStarted]: 'Not Started',
};

export enum EAutomationAction {
  Manual = 'manual',
  Rerun = 'rerun',
}

export interface IMerchantAutomationListResponse
  extends IResponseBase<IMerchantAutomationItem[]> {}
export interface IMerchantApplicationDetail extends IApplicationDetail {}

export interface IMerchantKycDetail extends IKycDetail {}

export interface IMerchantKycSiteScanDetail extends IKycSiteScanDetail {}

export interface IMerchantKycOfacControllingDetail
  extends IKycOfacControllingDetail {}

export interface IMerchantKycOfacBusinessDetail
  extends IKycOfacBusinessDetail {}

export interface IMerchantKycOfacPrincipalDetail
  extends IKycOfacPrincipalDetail {}

export interface IMerchantKycBankValidationDetail
  extends IKycBankValidationDetail {}

export interface IMerchantKycTinCheckBusinessDetail
  extends IKycTinCheckBusinessDetail {}

export interface IMerchantKycTinCheckPrincipalDetail
  extends IKycTinCheckPrincipalDetail {}

export interface IMerchantKycExperianPrincipalDetail
  extends IKycExperianPrincipalDetail {}

export interface IMerchantKycMatchInquiryDetail
  extends IKycMatchInquiryDetail {}

export interface IMerchantKycMatchLookupDetail extends IKycMatchLookupDetail {}

export interface IMerchantOfferInfo extends IOfferPricingPlanData {}

export interface IMerchantVarSheetListResponse
  extends IResponseBase<IMerchantVarsheetList> {}

export interface IMerchantPendingDocumentResponse
  extends IResponseBase<IMerchantPendingDocument> {}

export enum EMerchantTabKeys {
  application = 'application',
  offerDetails = 'offerDetails',
  equipment = 'equipments',
  uploads = 'uploads',
  notes = 'notes',
  configurations = 'configurations',
  kycIntegrations = 'kycIntegrations',
  automations = 'automations',
  infoRequests = 'infoRequests',
}

export const EMerchantTabLabels = {
  [EMerchantTabKeys.application]: 'Application',
  [EMerchantTabKeys.offerDetails]: 'Offer Details',
  [EMerchantTabKeys.equipment]: 'Equipment',
  [EMerchantTabKeys.uploads]: 'Uploads',
  [EMerchantTabKeys.notes]: 'Notes',
  [EMerchantTabKeys.configurations]: 'Configurations',
  [EMerchantTabKeys.kycIntegrations]: 'KYC Integrations',
  [EMerchantTabKeys.automations]: 'Automations',
  [EMerchantTabKeys.infoRequests]: 'Info Requests',
};

export const CMerchantDetailTabs = {
  [EMerchantTabKeys.application]: {
    label: EMerchantTabLabels[EMerchantTabKeys.application],
    // active: false,
  },
  [EMerchantTabKeys.offerDetails]: {
    label: EMerchantTabLabels[EMerchantTabKeys.offerDetails],
    // active: false,
  },
  [EMerchantTabKeys.equipment]: {
    label: EMerchantTabLabels[EMerchantTabKeys.equipment],
    // active: false,
  },
  [EMerchantTabKeys.uploads]: {
    label: EMerchantTabLabels[EMerchantTabKeys.uploads],
    // active: false,
  },
  [EMerchantTabKeys.notes]: {
    label: EMerchantTabLabels[EMerchantTabKeys.notes],
    // active: false,
  },
  [EMerchantTabKeys.configurations]: {
    label: EMerchantTabLabels[EMerchantTabKeys.configurations],
    // active: false,
  },
  [EMerchantTabKeys.kycIntegrations]: {
    label: EMerchantTabLabels[EMerchantTabKeys.kycIntegrations],
    // active: false,
  },
  [EMerchantTabKeys.automations]: {
    label: EMerchantTabLabels[EMerchantTabKeys.automations],
    // active: false,
  },
  [EMerchantTabKeys.infoRequests]: {
    label: EMerchantTabLabels[EMerchantTabKeys.infoRequests],
    // active: false,
  },
};

export enum EMerchantApplicationKeys {
  businessInfo = 'businessInfo',
  processingInfo = 'processingInfo',
  bankingInfo = 'bankingInfo',
  motoQuestionnaire = 'motoQuestionnaire',
  businessPrincipals = 'businessPrincipals',
  cardBrandElections = 'cardBrandElections',
  signatures = 'signatures',
  securityInformation = 'securityInformation',
  achProcessingInfo = 'achProcessingInfo',
}

export enum EMerchantKYCKeys {
  kycSitescan = 'kycSItescan',
  bankValidations = 'bankValidations',
  tinCheckBusiness = 'tinCheckBusiness',
  tinCheckPrincipal = 'tinCheckPrincipal',
  experianCreditCheck = 'experianCreditCheck',
  matchInquiry = 'matchInquiry',
  matchLookup = 'matchLookup',
  ofacLookup = 'ofacLookup',
}

export enum EMerchantConfigurationKeys {
  boardingConfiguration = 'boardingConfiguration',
  settlementReserves = 'settlementReserves',
  riskMonitoring = 'riskMonitoring',
}

export interface IMerchantAllowedStatusList {
  nextStatusesAllowed: IMerchantAllowedStatus[];
}

export interface IMerchantAllowedStatus {
  status: EMerchantStatus;
}
