import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';

import { CommonService } from './shared/services/common.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { AuthenticationService } from './auth/service';
import { EOnboardingPageId } from '@helpers/routes.enum';
import { EMerchantOnboardingStatus } from './business/authentication/models/merchant-prospect-status.enum';
import { MspMerchantApplicationStoreService } from './business/authentication/services/msp-merchant-application-store.service';
import { MspMerchantApplicationImplementService } from './business/authentication/services/msp-merchant-application-implement.service';
import { MspMerchantApplicationClientService } from '@clients/authentication/msp-merchant-application-client.service';
import { EMspMerchantApplicationSignatureRoleValue } from '@parameters/authentication/msp-merchant-application-list.parameter';
import { MSP_APPLICATION_PROGRESS_PAGE_URL } from '@parameters/routing/msp-application-progress.parameter';
import { GlobalConstantRoutes } from '@parameters/routing/global-constant-routes';
import { APPLICATION_PATH } from '@parameters/routing/application-path';
import { PORTAL } from '@parameters/routing/portal.parameter';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';
import { CoreConfig } from '@core/types/core-config';
import { NgClass } from '@angular/common';
import { VerticalLayoutModule } from './core/components/layout/vertical/vertical-layout.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgClass, VerticalLayoutModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    MspMerchantApplicationStoreService,
    MspMerchantApplicationImplementService,
    MspMerchantApplicationClientService,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: CoreConfig;
  private _unsubscribeAll: Subject<any>;
  /**
   * Constructor
   *
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param cmService
   * @param router
   * @param activatedRoute
   * @param _authenticationService
   * @param _MspAuthStoreService
   * @param _changeDetectorRef
   */
  constructor(
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    public cmService: CommonService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _MspAuthStoreService: MspMerchantApplicationStoreService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // this.cmService.isLoading = true;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const contentContainer = document.querySelector(
          '.sidebar-content-wrapper'
        );
        if (contentContainer) contentContainer.scrollTo(0, 0);
      }
    });
    setTimeout(() => {
      this.cmService.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }, 2000);
    this.getTitle();
    this._authenticationService.subject$.subscribe((stateData) => {
      const selectedApplication =
        this._authenticationService.getStoredSelectedMerchantApplicationData;
      if (
        selectedApplication.selectedApplicationMerchantId &&
        selectedApplication.selectedApplicationSignatureRole ===
          EMspMerchantApplicationSignatureRoleValue.primary
      ) {
        if (stateData.state === EMerchantOnboardingStatus.DRAFT) {
          const { onboardingProgressCurrentPageId } = stateData.data;
          this.router.navigate([
            `/onboarding/${this.mspAuthStore.selectedMerchantId}/` +
              (onboardingProgressCurrentPageId ||
                EOnboardingPageId.business_detail),
          ]);
        } else if (stateData.state === EMerchantOnboardingStatus.COMPLETED) {
          const route = `/${BUSINESS_PATH.portal}/${
            PORTAL.merchant
          }/${GlobalConstantRoutes.merchantDashboardWithId(
            this.mspAuthStore.selectedMerchantId
          )}`;
          this.router.navigate([route]);
        } else {
          this.router.navigate(['/login']);
        }
      } else {
        if (stateData.state === EMerchantOnboardingStatus.DRAFT) {
          const basePrefix = MSP_APPLICATION_PROGRESS_PAGE_URL.secondarySigner;
          const routeBase = `/${basePrefix}/${selectedApplication.selectedApplicationMerchantId}/`;
          this.router.navigate([
            routeBase +
              MSP_APPLICATION_PROGRESS_PAGE_URL.secondarySignerSummary,
          ]);
        } else if (stateData.state === EMerchantOnboardingStatus.COMPLETED) {
          const route = `/${BUSINESS_PATH.portal}/${PORTAL.merchant}/${APPLICATION_PATH.application}/${selectedApplication.selectedApplicationMerchantId}`;
          this.router.navigate([route]);
        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  public get mspAuthStore() {
    return this._MspAuthStoreService;
  }

  getTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          this._title.setTitle(data.title || 'Onboarding');
        });
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: CoreConfig) => {
        this.coreConfig = config;

        this._elementRef.nativeElement.classList.add(
          'vertical-layout',
          'vertical-menu-modern'
        );

        // Navbar
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          'navbar-floating',
          'navbar-static',
          'navbar-sticky',
          'navbar-hidden'
        );

        // Add class based on config options
        this._elementRef.nativeElement.classList.add('navbar-floating');

        // Footer
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          'footer-fixed',
          'footer-static',
          'footer-hidden'
        );

        // Add class based on config options
        this._elementRef.nativeElement.classList.add('footer-static');

        const appContentElement =
          this._elementRef.nativeElement.getElementsByClassName(
            'app-content'
          )[0];
        // Blank layout
        if (
          this.coreConfig.layout.menu.hidden &&
          this.coreConfig.layout.navbar.hidden
        ) {
          this._elementRef.nativeElement.classList.add('blank-page');
          // ! Fix: Transition issue while coming from blank page

          if (appContentElement) {
            this._renderer.setAttribute(
              this._elementRef.nativeElement.getElementsByClassName(
                'app-content'
              )[0],
              'style',
              'transition:none'
            );
          }
        } else {
          this._elementRef.nativeElement.classList.remove('blank-page');
          // ! Fix: Transition issue while coming from blank page
          if (appContentElement) {
            setTimeout(() => {
              this._renderer.setAttribute(
                appContentElement,
                'style',
                'transition:300ms ease all'
              );
            }, 0);
          }
          // If navbar hidden
          if (this.coreConfig.layout.navbar.hidden) {
            this._elementRef.nativeElement.classList.add('navbar-hidden');
          }
          // Menu (Vertical menu hidden)
          if (this.coreConfig.layout.menu.hidden) {
            this._renderer.setAttribute(
              this._elementRef.nativeElement,
              'data-col',
              '1-column'
            );
          } else {
            this._renderer.removeAttribute(
              this._elementRef.nativeElement,
              'data-col'
            );
          }
        }
      });
  }

  get validateSidebar() {
    const url = this.router.url.split('?return-url')[0];
    return (url.includes('login') ||
      url.includes('reset-password') ||
      url.includes('signup') ||
      url.includes('forgot-password') ||
      url.includes('onboarding') ||
      url.includes('')) &&
      !url.includes('dashboard')
      ? 'onboarding'
      : '';
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._authenticationService.subject$.unsubscribe();
    this._authenticationService.subject$.complete();
  }
}
