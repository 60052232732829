import { NgModule } from '@angular/core';
import { PaymentAccountDropdown } from './payment-account-dropdown/payment-account-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  GtrSelectComponent,
  GtrIconComponent,
} from '@gettrx/core-components-angular';
import { PaymentConfirmPasswordModule } from '@atoms/payment-confirm-password/payment-confirm-password.module';

@NgModule({
  declarations: [PaymentAccountDropdown],
  imports: [
    GtrSelectComponent,
    ReactiveFormsModule,
    GtrIconComponent,
    RouterModule,
    PaymentConfirmPasswordModule,
  ],
  exports: [PaymentAccountDropdown],
})
export class PaymentAccountDropdownModule {}
