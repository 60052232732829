import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  GtrSelectComponent,
  GtrIconComponent,
} from '@gettrx/core-components-angular';
import { PartnerAccountDropdownComponent } from './partner-account-dropdown/partner-account-dropdown.component';
import { PartnerAccountSelectClientService } from '@clients/developers/partner-account-select-client-service';
import { PartnerAccountSelectImplementService } from '@implements/partner-account-select-implement.service';

@NgModule({
  declarations: [PartnerAccountDropdownComponent],
  imports: [GtrSelectComponent, ReactiveFormsModule, GtrIconComponent, RouterModule],
  exports: [PartnerAccountDropdownComponent],
  providers: [
    PartnerAccountSelectClientService,
    PartnerAccountSelectImplementService,
  ],
})
export class PartnerAccountDropdownModule {}
